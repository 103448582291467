import { useParams } from '@solidjs/router';
import { Component, For, JSX, Show } from 'solid-js';
import IconClose from '~/assets/icons/close.svg';
import IconFilledCheck from '~/assets/images/property/filledCheck.svg';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useErrorMessage } from '~/hooks/useErrorMessage';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { StepError } from '~/state/mainAppState';
import { RentalApplicationErrorCode } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { JumpToStepUseCase } from '~/use-cases/rental-applications/jumpToStepUseCase';
import { cn } from '~/utils/classnames';
import { TintedSVG } from '../TintedSVG';

type LeftStepperProps = {
  class?: string;
  step: number;
  steps: RentalApplicationSteps[];
  stepsMap?: Map<RentalApplicationSteps, string>;
  img?: string;
};

export const LeftStepper: Component<LeftStepperProps> = (props) => {
  const { model } = usePresenter(RentalApplicationPresenter);
  const { getErrorMessage } = useErrorMessage();

  const renderError = (step: RentalApplicationSteps) => {
    let firstErrorCode: undefined | RentalApplicationErrorCode;
    const errorCodes = model()?.errors?.[step];

    if (step === RentalApplicationSteps.WORK_HISTORY) {
      firstErrorCode =
        (model()?.errors?.singleFiled as StepError)?.annualIncome ||
        Object.values(
          ((Array.isArray(errorCodes)
            ? errorCodes?.filter((errorCodesObj) => Object.values(errorCodesObj).some((errorCode) => errorCode !== undefined)).at(0)
            : errorCodes) ?? {}) as StepError
        )
          .filter((errorCode) => errorCode !== undefined)
          .at(0);
    } else {
      firstErrorCode = Object.values(
        ((Array.isArray(errorCodes)
          ? errorCodes?.filter((errorCodesObj) => Object.values(errorCodesObj).some((errorCode) => errorCode !== undefined)).at(0)
          : errorCodes) ?? {}) as StepError
      )
        .filter((errorCode) => errorCode !== undefined)
        .at(0);
    }
    const errorMessage = getErrorMessage(firstErrorCode);

    return errorMessage;
  };

  const renderTips = (step: RentalApplicationSteps) => {
    const selectedInterestedUnits = model()?.unitsOfInterest?.items.filter((unit) => unit.isSelected);
    const isShowPrevSelectedUnit = () => {
      const firstPrevSelectedUnitId = model()?.preSelectedUnits?.at(0)?.id;

      if (selectedInterestedUnits?.length && !model()?.isOpenToAllUnits) {
        return true;
      }

      if (firstPrevSelectedUnitId && !model()?.isOpenToAllUnits) {
        return true;
      }

      return false;
    };

    return (
      <Show when={step === RentalApplicationSteps.POTENTIAL_UNITS && isShowPrevSelectedUnit()} fallback={undefined}>
        <div class="flex items-center gap-1.5">
          <i class="block size-1.5 shrink-0 rounded-full bg-[#00AE66]" />
          <span class="text-xs text-[#00AE66]">{selectedInterestedUnits?.at(0)?.name ?? model()?.preSelectedUnits?.at(0)?.title}</span>
        </div>
      </Show>
    );
  };

  return (
    <div class={cn('flex w-full flex-col px-5 pb-6', props.class)}>
      <div />
      <img class="py-4" src={props.img} />
      <For each={props.steps}>
        {(step, index) => (
          <Stepper
            errorMessage={renderError(step)}
            tips={renderTips(step)}
            step={step}
            index={index()}
            currentStep={props.step}
            steps={props.steps}
            stepsMap={props.stepsMap}
          />
        )}
      </For>
    </div>
  );
};

interface StepperProps {
  index: number;
  step: RentalApplicationSteps;
  steps: RentalApplicationSteps[];
  stepsMap?: Map<RentalApplicationSteps, string>;
  currentStep: number;
  errorMessage?: string;
  tips?: JSX.Element;
}

const Stepper = (props: StepperProps) => {
  const params = useParams();
  const { execute: JumpToStep } = useUseCase(JumpToStepUseCase);

  const onStepClick = () => {
    if (props.currentStep < props.index) {
      return;
    }

    JumpToStep({ to: props.step, credentials: { id: params.id, password: params.password } });
  };

  return (
    <div
      class={cn('flex items-start justify-start gap-2 pb-1', { 'cursor-pointer': props.index < props.currentStep })}
      onClick={onStepClick}>
      <div
        class={cn(
          'relative z-10 flex h-full items-center justify-start gap-2',
          props.currentStep >= props.index ? 'text-primary-color' : 'text-auxiliary-text'
        )}>
        <div class="flex h-full flex-col">
          <div
            class={cn(
              'mb-1 flex size-[18px] shrink-0 items-center justify-center rounded-full border border-auxiliary-text bg-white text-sm font-medium',
              { 'bg-white': props.currentStep < props.index },
              { 'bg-[#E9E8FF] border-[#E9E8FF]': props.currentStep > props.index },
              { 'border-primary-color': props.currentStep === props.index },
              { 'bg-danger border-danger': props.errorMessage }
            )}>
            <Show
              when={props.errorMessage}
              fallback={
                <Show when={props.index < props.currentStep}>
                  <TintedSVG svg={IconFilledCheck} color="#0C48BC" />
                </Show>
              }>
              <TintedSVG svg={IconClose} color="#ffffff" class="size-3" />
            </Show>
          </div>
          <Show when={props.index !== props.steps.length - 1}>
            <div class={cn('ml-[9px] h-6 w-px grow bg-auxiliary-text', { 'bg-primary-color': props.index < props.currentStep })} />
          </Show>
        </div>
      </div>
      <div class="flex-1 overflow-hidden">
        <span
          class={cn(
            'flex w-fit text-sm font-medium leading-[18px] text-text-level03',
            { 'font-semibold text-primary-color': props.currentStep === props.index },
            { 'text-danger': props.errorMessage }
          )}>
          {props.stepsMap?.get(props.step as RentalApplicationSteps)}
        </span>
        <Show when={props.errorMessage} fallback={props.tips}>
          <div class="flex items-center gap-1.5">
            <div class="size-1.5 shrink-0 rounded-full bg-danger" />
            <span class="truncate text-xs text-danger">{props.errorMessage}</span>
          </div>
        </Show>
      </div>
    </div>
  );
};
