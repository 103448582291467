import {
  APPLICANT_INFORMATION,
  POTENTIAL_UNITS,
  RENTAL_HISTORY,
  WORK_HISTORY,
  QUESTIONS,
  TERM_AND_CONDITIONS,
  PAYMENT_DETAILS,
  CONFIRM_YOUR_IDENTITY,
} from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { useLocalization } from '~/hooks/useLocalization';
import { User } from '~/state/mainAppState';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';

export interface PresentableSteps {
  steps: RentalApplicationSteps[];
  currentStepIndex: number;
  stepMap: Map<RentalApplicationSteps, string>;
  currentStep: RentalApplicationSteps;
}

export class RentalApplicationStepsPresenter extends Presenter<PresentableSteps> {
  protected createModel(state: User): PresentableSteps {
    const { t } = useLocalization();

    const stepMap = new Map<RentalApplicationSteps, string>([
      [RentalApplicationSteps.APPLICANT_INFORMATION, t(APPLICANT_INFORMATION)],
      [RentalApplicationSteps.POTENTIAL_UNITS, t(POTENTIAL_UNITS)],
      [RentalApplicationSteps.RENTAL_HISTORY, t(RENTAL_HISTORY)],
      [RentalApplicationSteps.WORK_HISTORY, t(WORK_HISTORY)],
      [RentalApplicationSteps.QUESTIONS, t(QUESTIONS)],
      [RentalApplicationSteps.TERMS_AND_CONDITIONS, t(TERM_AND_CONDITIONS)],
      [RentalApplicationSteps.PAYMENT, t(PAYMENT_DETAILS)],
      [RentalApplicationSteps.TRANS_UNION_SCREENING, t(CONFIRM_YOUR_IDENTITY)],
    ]);

    const settings = state.rentalApplication.settings;
    const currentStep = state.rentalApplication.currentStep;
    const steps: RentalApplicationSteps[] = [RentalApplicationSteps.APPLICANT_INFORMATION];

    steps.push(RentalApplicationSteps.POTENTIAL_UNITS);
    steps.push(RentalApplicationSteps.RENTAL_HISTORY);
    steps.push(RentalApplicationSteps.WORK_HISTORY);

    if (settings?.questions && settings.questions.length > 0) {
      steps.push(RentalApplicationSteps.QUESTIONS);
    }

    steps.push(RentalApplicationSteps.TERMS_AND_CONDITIONS);

    if (settings?.requiresPayment) {
      steps.push(RentalApplicationSteps.PAYMENT);
    }

    if (settings?.requiresScreening) {
      steps.push(RentalApplicationSteps.TRANS_UNION_SCREENING);
    }

    const currentStepIndex =
      currentStep && stepMap.has(currentStep)
        ? steps.indexOf(currentStep)
        : state.rentalApplication.application.isDraft
        ? 0
        : steps.length - 1;

    return { steps, currentStepIndex, stepMap, currentStep: state.rentalApplication.currentStep };
  }
}
