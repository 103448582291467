import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '../magicUseCase';
import { SaveRentalApplicationUseCase } from './application-steps/saveRentalApplicationUseCase';
import { SetCurrentStepUseCase } from './application-steps/setCurrentStepUseCase';
import { RENTAL_APPLICATION_STEP_URLS } from './rentalApplicationStepUrls';

export class JumpToStepUseCase extends MagicUseCase {
  protected async runLogic(request: { to: RentalApplicationSteps; credentials: RentalApplicationCredentials }) {
    let application = this.getState().user.rentalApplication.application;
    let credentials: RentalApplicationCredentials = request.credentials;

    if (!credentials.id || !credentials.password) {
      const newApplication = await rentalApplicationGateway.createApplication();

      application = {
        ...application,
        credentials: newApplication.credentials,
        isDraft: newApplication.isDraft,
      };
      credentials = newApplication.credentials;
    }

    const pathSection = credentials ? `/${credentials.id}/${credentials.password}` : '';

    this.getState().user.rentalApplication.application = application;

    if (
      !application.isDraft &&
      !this.getState()?.user.rentalApplication.isPaid &&
      this.getState().user.rentalApplication.settings?.requiresPayment
    ) {
      await new SetCurrentStepUseCase().execute(RentalApplicationSteps.PAYMENT);
      this.navigate(`/rental-applications${pathSection}/payment`);
      return;
    }

    if (application?.isDraft) {
      await new SaveRentalApplicationUseCase().execute(credentials);
    }

    await new SetCurrentStepUseCase().execute(request.to);
    this.navigate(`/rental-applications${pathSection}${RENTAL_APPLICATION_STEP_URLS[request.to]}`);
  }
}
