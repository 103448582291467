import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';

export const RENTAL_APPLICATION_STEP_URLS: Partial<Record<RentalApplicationSteps, string>> = {
  [RentalApplicationSteps.APPLICANT_INFORMATION]: '/applicant-information',
  [RentalApplicationSteps.POTENTIAL_UNITS]: '/potential-units',
  [RentalApplicationSteps.RENTAL_HISTORY]: '/rental-history',
  [RentalApplicationSteps.WORK_HISTORY]: '/employment-income',
  [RentalApplicationSteps.QUESTIONS]: '/rental-questions',
  [RentalApplicationSteps.TERMS_AND_CONDITIONS]: '/terms-and-conditions',
  [RentalApplicationSteps.PAYMENT]: '/payment',
  [RentalApplicationSteps.TRANS_UNION_SCREENING]: '/transunion-connection',
  [RentalApplicationSteps.APPLICATION_SUBMITTED]: '/application-submitted',
};
