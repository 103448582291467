import IconRentalApplication from '~/assets/icons/rentalApplication.png';
import { RENTAL_APPLICATION } from '~/assets/strings';
import { LocalizationButton } from '~/components/layouts/Navigation/localization/LocalizationButton';
import { useLocalization } from '~/hooks/useLocalization';

const RentalApplicationHeader = () => {
  const { t } = useLocalization();

  return (
    <div class="flex w-full items-center justify-center gap-2 bg-white px-0 py-4 lg:px-10">
      <div class="flex w-11/12 items-center justify-center lg:w-full">
        <div class="flex w-full items-center gap-2">
          <div class="shrink-0 rounded-full bg-primary-color p-2">
            <img src={IconRentalApplication} />
          </div>
          <div class=" text-xl font-semibold">{t(RENTAL_APPLICATION)}</div>
        </div>
        <div class="m-2 flex shrink-0 flex-row justify-end">
          <LocalizationButton />
        </div>
      </div>
    </div>
  );
};

export default RentalApplicationHeader;
